import { ConnectWallet } from "@thirdweb-dev/react";
import logo from "../img/logo.png";
const Header = () => {
	return (
		<>
			<header
				className='MuiStack-root css-gb0pqs-MuiStack-root'
				id='header'>
				<div className='MuiStack-root css-1ruo2lh-MuiStack-root'>
					<div className='MuiStack-root css-1k5yend-MuiStack-root'>
						<div className='MuiStack-root css-1hkwzyj-MuiStack-root'>
							<div className='MuiBox-root css-1u2yd0y'>
								<div
									className='MuiBox-root css-1e8h2ya'
									data-active='false'></div>
								<div
									className='MuiBox-root css-1e8h2ya'
									data-active='false'></div>
								<div
									className='MuiBox-root css-1e8h2ya'
									data-active='false'></div>
							</div>
							<a
								className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1kndmtp-MuiTypography-root-MuiLink-root'
								href='/'>
								<img
									src={logo}
									alt='BitroCoin Logo'
									width={"120px"}
								/>
							</a>
						</div>
					</div>
					<div className='MuiStack-root css-1hyf038-MuiStack-root'>
						{/* <button
							className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-5nbz5j-MuiButtonBase-root-MuiButton-root'
							tabIndex='0'
							type='button'>
							Connect Wallet
							<span className='MuiButton-icon MuiButton-endIcon MuiButton-iconSizeMedium css-1gnd1fd-MuiButton-endIcon'></span>
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</button> */}
						<ConnectWallet
							className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-5nbz5j-MuiButtonBase-root-MuiButton-root'
							tabIndex='0'
						/>
					</div>
				</div>
			</header>
		</>
	);
};
export default Header;
