import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import "./assets/main.css";
import "./assets/main001.css";
import "./assets/main002.css";
import "./assets/main003.css";
import "./assets/main004.css";
import "./assets/main005.css";
import "./assets/main006.css";
import "./assets/main007.css";
import "./assets/main008.css";
import "./assets/main009.css";
import "./assets/main010.css";
import "./assets/fonts.css";
import "./assets/custom-fonts.css";

const activeChain = "polygon";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<ThirdwebProvider
			activeChain={activeChain}
			clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}>
			<App />
		</ThirdwebProvider>
	</React.StrictMode>
);
