import React, { useEffect, useState, useRef } from "react";
import {
	useAddress,
	ConnectWallet,
	useContract,
	useContractRead,
	useContractWrite,
	useSwitchChain,
	useChain,
	useChainId,
} from "@thirdweb-dev/react";
import { formatEther, parseEther, zeroAddress } from "viem";
import {
	faBarcode,
	faCoins,
	faFilePdf,
	faQrcode,
	faRandom,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import btrcIcon from "../img/coin.png";
import maticIcon from "../img/matic.png";
const BuyForm = () => {
	const [connected, setAddress] = useState(zeroAddress);
	const address = useAddress();

	const _sponsor = "0x43720aBf27F94E19460f0e9b3983f211903a3077";

	const SweetA = withReactContent(Swal);

	const switchChain = useSwitchChain();

	const chain = useChain();

	const chainId = useChainId();

	const [connectedChain, setConnectedChain] = useState(null);
	const [btrcAmount, setBtrcAmount] = useState("0");
	const [maticAmount, setMaticAmount] = useState("0");
	const [lastUpdated, setLastUpdated] = useState(null);

	const timeoutRef = useRef(null);

	const [btrcSales, setbtrcSales] = useState({});

	const [tokenPrice, setTokenPrice] = useState("1.4049");

	const [_hasBalance, setHasBalance] = useState(false);

	const [userBalance, setUserBalance] = useState({
		usdtBalance: 0,
		maticBalance: 0,
	});

	const [maticPrice, setMTICPrice] = useState("0.6955");

	const { contract: btrcSalesContract } = useContract(
		btrcSales.address,
		btrcSales.abi
	);

	const { data: _btrcPrice } = useContractRead(
		btrcSalesContract,
		"lastPrice"
	);

	const { data: _maticPrice } = useContractRead(
		btrcSalesContract,
		"getMATICRate"
	);

	// change network
	const changeNetwork = async () => {
		try {
			await switchChain(137);
		} catch (e) {}
	};

	const { mutateAsync: updateTokenPrice } = useContractWrite(
		btrcSalesContract,
		"setCurrentPrice"
	);

	// buy with MATIC
	const { mutateAsync: purchaseWithMATIC } = useContractWrite(
		btrcSalesContract,
		"purchase"
	);

	// // process buy with usdt
	// const buyWithUSDT = async () => {
	// 	// return PleaseWaitMsg();
	// 	if (usdtAmount < 10) {
	// 		SweetA.fire({
	// 			title: <strong>Wrong Amount</strong>,
	// 			html: (
	// 				<strong>
	// 					Amount should be greater than or equal to $1
	// 				</strong>
	// 			),
	// 			icon: "error",
	// 		});
	// 		return;
	// 	}
	// 	try {
	// 		purchaseWithUSDT({
	// 			args: [_sponsor, parseEther(amountToBuy).toString()],
	// 		});
	// 	} catch (e) {}
	// };

	// process buy with matic

	const buyWithMATIC = async () => {
		// return PleaseWaitMsg();
		if (maticAmount < (Number(11.5) / Number(maticPrice)).toFixed(0)) {
			SweetA.fire({
				title: <strong>Wrong Amount</strong>,
				html: (
					<strong>
						Amount should be greater than or equal to{" "}
						{(Number(11.5) / Number(maticPrice)).toFixed(0)} MATIC
					</strong>
				),
				icon: "error",
			});
			return;
		}
		try {
			purchaseWithMATIC({
				args: [_sponsor],
				overrides: {
					value: parseEther(maticAmount),
					gasPrice: parseEther("0.00000004597"),
					gasLimit: 950000,
				},
			});
		} catch (e) {}
	};

	// update token Price
	const topUpTokenValue = async (value) => {
		try {
			updateTokenPrice({
				args: [parseEther(value).toString()],
				overrides: {
					gasPrice: parseEther("0.00000006597"),
					gasLimit: 150000,
				},
			}).then(async () => {
				window.location.reload();
			});
		} catch (e) {
			console.log(e);
		}
	};

	// Debounced function to handle blur event
	const handleBlur = (value) => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(async () => {
			await topUpTokenValue(value);
		}, 300); // Adjust the delay as needed
	};

	useEffect(() => {
		if (chain) {
			setConnectedChain({
				name: chainId === 137 ? chain.name : "Wrong Network!",
				id: chainId,
			});
		}
	}, [chain, chainId]);

	useEffect(() => {
		fetch("BitRoCoinPresales.json").then((response) => {
			response.json().then((contract) => {
				setbtrcSales(contract);
			});
		});
	}, []);

	useEffect(() => {
		if (lastUpdated === "btrc" && btrcAmount !== "") {
			setMaticAmount(
				(
					(Number(btrcAmount) * Number(tokenPrice)) /
					Number(maticPrice)
				).toFixed(5)
			);
		}
	}, [btrcAmount, lastUpdated]);

	useEffect(() => {
		if (lastUpdated === "matic" && maticAmount !== "") {
			setBtrcAmount(
				(
					(Number(maticAmount) * Number(maticPrice)) /
					Number(tokenPrice)
				).toFixed(5)
			);
		}
	}, [maticAmount, lastUpdated]);

	useEffect(() => {
		if (_btrcPrice)
			setTokenPrice(parseFloat(formatEther(_btrcPrice)).toFixed(8));
	}, [_btrcPrice]);

	useEffect(() => {
		if (_maticPrice)
			setMTICPrice(parseFloat(formatEther(_maticPrice)).toFixed(8));
	}, [_maticPrice]);

	useEffect(() => {
		if (address) setAddress(address);
		if (!address) setAddress(zeroAddress);
	}, [address]);

	return (
		<>
			<section className='MuiStack-root css-1c888u-MuiStack-root'>
				<div className='MuiStack-root css-gfnv8u-MuiStack-root'>
					<div className='MuiStack-root css-1r71o2u-MuiStack-root'>
						<h1 className='MuiTypography-root MuiTypography-h1 css-3hxpda-MuiTypography-root'>
							<span
								fontSize='inherit'
								className='css-cpe3t8'>
								Innovative, Secured, Community-Driven:
								<br />
							</span>
							Advancing the Global Blockchain Ecosystem.
						</h1>
						<div className='MuiStack-root css-hgsfo-MuiStack-root'>
							<a
								className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary css-1ylnqfs-MuiButtonBase-root-MuiButton-root'
								tabIndex='0'
								target='_blank'
								href='https://bitrocoin.io/whitepaper.pdf'
								rel='noreferrer'>
								<span className='MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-gppkdk-MuiButton-startIcon'>
									<FontAwesomeIcon icon={faFilePdf} />
								</span>
								Whitepaper
								<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
							</a>
							{/* <a
								className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary css-1ylnqfs-MuiButtonBase-root-MuiButton-root'
								tabIndex='0'
								href='/'
								target='_blank'
								rel='noreferrer'>
								<span className='MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-gppkdk-MuiButton-startIcon'>
									<svg
										width='25'
										height='24'
										viewBox='0 0 25 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M12.7891 22L12.5391 19H12.2891C9.9224 19 7.91406 18.175 6.26406 16.525C4.61406 14.875 3.78906 12.8667 3.78906 10.5C3.78906 8.13333 4.61406 6.125 6.26406 4.475C7.91406 2.825 9.9224 2 12.2891 2C13.4724 2 14.5766 2.22083 15.6016 2.6625C16.6266 3.10417 17.5266 3.7125 18.3016 4.4875C19.0766 5.2625 19.6849 6.1625 20.1266 7.1875C20.5682 8.2125 20.7891 9.31667 20.7891 10.5C20.7891 11.75 20.5849 12.95 20.1766 14.1C19.7682 15.25 19.2099 16.3167 18.5016 17.3C17.7932 18.2833 16.9516 19.175 15.9766 19.975C15.0016 20.775 13.9391 21.45 12.7891 22ZM12.2641 15.975C12.5474 15.975 12.7891 15.875 12.9891 15.675C13.1891 15.475 13.2891 15.2333 13.2891 14.95C13.2891 14.6667 13.1891 14.425 12.9891 14.225C12.7891 14.025 12.5474 13.925 12.2641 13.925C11.9807 13.925 11.7391 14.025 11.5391 14.225C11.3391 14.425 11.2391 14.6667 11.2391 14.95C11.2391 15.2333 11.3391 15.475 11.5391 15.675C11.7391 15.875 11.9807 15.975 12.2641 15.975ZM11.5391 12.8H13.0391C13.0391 12.3 13.0891 11.95 13.1891 11.75C13.2891 11.55 13.6057 11.1833 14.1391 10.65C14.4391 10.35 14.6891 10.025 14.8891 9.675C15.0891 9.325 15.1891 8.95 15.1891 8.55C15.1891 7.7 14.9016 7.0625 14.3266 6.6375C13.7516 6.2125 13.0724 6 12.2891 6C11.5557 6 10.9391 6.20417 10.4391 6.6125C9.93906 7.02083 9.58906 7.51667 9.38906 8.1L10.7891 8.65C10.8724 8.36667 11.0307 8.0875 11.2641 7.8125C11.4974 7.5375 11.8391 7.4 12.2891 7.4C12.7391 7.4 13.0766 7.525 13.3016 7.775C13.5266 8.025 13.6391 8.3 13.6391 8.6C13.6391 8.88333 13.5557 9.1375 13.3891 9.3625C13.2224 9.5875 13.0224 9.81667 12.7891 10.05C12.2057 10.55 11.8516 10.9458 11.7266 11.2375C11.6016 11.5292 11.5391 12.05 11.5391 12.8Z'
											fill='currentColor'></path>
									</svg>
								</span>
								How to buy?
								<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
							</a> */}
							<a
								className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary css-1ylnqfs-MuiButtonBase-root-MuiButton-root'
								tabIndex='0'
								href='https://polygonscan.com/token/0x4658ac2b5de582de93c08f08e9236ff014dd8570#code'
								target='_blank'
								rel='noreferrer'>
								<span className='MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-gppkdk-MuiButton-startIcon'>
									<FontAwesomeIcon icon={faQrcode} />
								</span>
								Token Address 0x465.......d8570
								<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
							</a>

							<a
								className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary css-1ylnqfs-MuiButtonBase-root-MuiButton-root'
								href='https://coinmarketcap.com/currencies/bitro-coin/'
								target='_blank'
								rel='noreferrer'>
								<span
									className='MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-gppkdk-MuiButton-startIcon'
									aria-hidden='true'>
									<FontAwesomeIcon icon={faCoins} />
								</span>
								<p className='MuiTypography-root MuiTypography-body1 css-1o7vh8o-MuiTypography-root'>
									{" "}
									View BTRC on CMC
								</p>
							</a>
						</div>
						{/* <div className='MuiStack-root css-3cxtmu-MuiStack-root'>
							<div
								className='MuiStack-root css-1i45cbl-MuiStack-root'
								id='referral-block'>
								<h6 className='MuiTypography-root MuiTypography-subtitle1 css-1pbg378-MuiTypography-root'>
									My referral link:
								</h6>
								<div className='MuiStack-root css-l6e76-MuiStack-root'>
									<div className='MuiBox-root css-1m96pdh'>
										<div className='MuiBox-root css-1uu41fv'>
											<p className='MuiTypography-root MuiTypography-body1 css-13f7x5h-MuiTypography-root'>
												Generate your referral link!
											</p>
											<button
												className='MuiButtonBase-root Mui-disabled MuiIconButton-root Mui-disabled MuiIconButton-sizeSmall css-8prnfn-MuiButtonBase-root-MuiIconButton-root'
												tabIndex='-1'
												type='button'
												disabled=''
												aria-label='Copied'
												data-mui-internal-clone-element='true'>
												<span
													className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
													aria-hidden='true'>
													<svg
														width='14'
														height='15'
														viewBox='0 0 14 15'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'>
														<path
															d='M1.44141 15C1.05469 15 0.723633 14.8623 0.448242 14.5869C0.172851 14.3115 0.0351562 13.9805 0.0351562 13.5938V3.51563C0.0351562 3.31641 0.102142 3.14941 0.236113 3.01465C0.370098 2.87988 0.536113 2.8125 0.73416 2.8125C0.93222 2.8125 1.09961 2.87988 1.23633 3.01465C1.37305 3.14941 1.44141 3.31641 1.44141 3.51563V13.5938H9.64453C9.84375 13.5938 10.0107 13.6607 10.1455 13.7947C10.2803 13.9287 10.3477 14.0947 10.3477 14.2928C10.3477 14.4908 10.2803 14.6582 10.1455 14.7949C10.0107 14.9316 9.84375 15 9.64453 15H1.44141ZM4.25391 12.1875C3.86719 12.1875 3.53613 12.0498 3.26074 11.7744C2.98535 11.499 2.84766 11.168 2.84766 10.7813V1.40625C2.84766 1.01953 2.98535 0.688477 3.26074 0.413087C3.53613 0.137696 3.86719 0 4.25391 0H11.7539C12.1406 0 12.4717 0.137696 12.7471 0.413087C13.0225 0.688477 13.1602 1.01953 13.1602 1.40625V10.7813C13.1602 11.168 13.0225 11.499 12.7471 11.7744C12.4717 12.0498 12.1406 12.1875 11.7539 12.1875H4.25391ZM4.25391 10.7813H11.7539V1.40625H4.25391V10.7813Z'
															fill='currentColor'></path>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<button
										className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-fullWidth css-10vrmfv-MuiButtonBase-root-MuiButton-root'
										tabIndex='0'
										type='button'>
										<p className='MuiTypography-root MuiTypography-body1 css-ug9vo9-MuiTypography-root'>
											Connect Wallet
										</p>
										<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
									</button>
								</div>
							</div>
						</div> */}
					</div>
					<div className='MuiStack-root css-uq0mcm-MuiStack-root'>
						<div className='MuiPaper-root MuiPaper-sale-wrapper MuiPaper-rounded css-m0egzq-MuiPaper-root'>
							<div className='MuiStack-root css-18t4pgp-MuiStack-root'>
								<div className='MuiStack-root css-1gszfea-MuiStack-root'>
									<h6 className='MuiTypography-root MuiTypography-h6 css-1moux6b-MuiTypography-root'>
										Buy $BTRC on Seed Round!
									</h6>
									<div className='MuiStack-root css-1z0smq0-MuiStack-root'>
										<div className='MuiStack-root css-niqf4j-MuiStack-root'>
											<div className='MuiStack-root css-1n9in09-MuiStack-root'>
												<div className='MuiPaper-root MuiPaper-primary MuiPaper-rounded css-1okyjke-MuiPaper-root'>
													<p className='MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1j4d1rf-MuiTypography-root'>
														+20%
													</p>
												</div>
												<p className='MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-7uq90z-MuiTypography-root'>
													<b>
														90 Days Target Price
														2.65$
													</b>
												</p>
											</div>
											<div className='MuiStack-root css-1n9in09-MuiStack-root'>
												<div className='MuiPaper-root MuiPaper-primary MuiPaper-rounded css-1hxhx8o-MuiPaper-root'>
													<p className='MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1j4d1rf-MuiTypography-root'>
														2,596,263 of 50mln
													</p>
												</div>
												<p className='MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-7uq90z-MuiTypography-root'>
													<b>$BTRC Sold</b>
												</p>
											</div>
										</div>
									</div>
									<div className='MuiStack-root css-eggh11-MuiStack-root'>
										<div className='MuiBox-root css-1v6qszk'>
											<div
												className='MuiStack-root css-1jkt443-MuiStack-root'
												style={{ width: "91%" }}>
												<div className='MuiBox-root css-scxgqe'></div>
											</div>
											<p
												className='MuiTypography-root MuiTypography-body1 css-1w48nzr-MuiTypography-root'
												style={{ left: "91%" }}>
												$2,801,030
											</p>
										</div>
										<div className='MuiStack-root css-gmwslw-MuiStack-root'>
											<p className='MuiTypography-root MuiTypography-body1 css-eg8puf-MuiTypography-root'>
												<b>$BTRC Price:</b> $
												{Number(tokenPrice).toFixed(2)}
											</p>
											<p className='MuiTypography-root MuiTypography-body1 css-eg8puf-MuiTypography-root'>
												<b>Target:</b> $3,065,500
											</p>
										</div>
									</div>
									<div className='MuiStack-root css-1b4i427-MuiStack-root'>
										<div className='MuiStack-root css-1ega58k-MuiStack-root'>
											<div className='MuiStack-root MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-jur15k-MuiStack-root-MuiPaper-root'>
												<div className='MuiStack-root css-95rbei-MuiStack-root'>
													<span
														className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-9ery1m-MuiIcon-root'
														aria-hidden='true'>
														<svg
															width='24'
															height='25'
															viewBox='0 0 24 25'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'>
															<path
																d='M11.9188 1L5.78174 4.42212L8.03797 5.6864L11.9188 3.52849L15.7995 5.6864L18.0557 4.42212L11.9188 1Z'
																fill='#F0B90B'></path>
															<path
																d='M15.7995 7.47375L18.0557 8.73803V11.2665L14.175 13.4244V17.7403L11.9188 19.0045L9.66245 17.7403V13.4244L5.78174 11.2665V8.73803L8.03797 7.47375L11.9188 9.63174L15.7995 7.47375Z'
																fill='#F0B90B'></path>
															<path
																d='M18.0551 13.0539V15.5824L15.7988 16.8466V14.3182L18.0551 13.0539Z'
																fill='#F0B90B'></path>
															<path
																d='M15.7764 18.6339L19.6572 16.476V12.1602L21.9134 10.8959V17.7403L15.7764 21.1624V18.6339Z'
																fill='#F0B90B'></path>
															<path
																d='M19.6572 7.84432L17.4009 6.58011L19.6572 5.31583L21.9134 6.58011V9.1086L19.6572 10.3728V7.84432Z'
																fill='#F0B90B'></path>
															<path
																d='M9.66211 22.078V19.5495L11.9184 20.8137L14.1747 19.5495V22.078L11.9184 23.3422L9.66211 22.078Z'
																fill='#F0B90B'></path>
															<path
																d='M8.03797 16.8466L5.78174 15.5824V13.0539L8.03797 14.3182V16.8466Z'
																fill='#F0B90B'></path>
															<path
																d='M11.9184 7.84432L9.66211 6.58011L11.9184 5.31583L14.1747 6.58011L11.9184 7.84432Z'
																fill='#F0B90B'></path>
															<path
																d='M6.4354 6.58011L4.17909 7.84432V10.3728L1.92285 9.1086V6.58011L4.17909 5.31583L6.4354 6.58011Z'
																fill='#F0B90B'></path>
															<path
																d='M1.92285 10.8959L4.17909 12.1602V16.476L8.05988 18.6339V21.1624L1.92285 17.7403V10.8959Z'
																fill='#F0B90B'></path>
														</svg>
													</span>
													<p className='MuiTypography-root MuiTypography-body1 css-1d4qkf7-MuiTypography-root'>
														BSC:
													</p>
													<a
														className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
														href='https://bscscan.com/token/0x355424b795F5a10Ff0AEAF2a97Ca8caA49A74909'
														target='_blank'
														rel='noreferrer'>
														<p className='MuiTypography-root MuiTypography-body1 css-1o7vh8o-MuiTypography-root'>
															0x355...909
														</p>
													</a>
												</div>
												<button
													className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-8prnfn-MuiButtonBase-root-MuiIconButton-root'
													tabIndex='0'
													type='button'
													aria-label='Copied'
													data-mui-internal-clone-element='true'>
													<span
														className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
														aria-hidden='true'>
														<svg
															width='14'
															height='15'
															viewBox='0 0 14 15'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'>
															<path
																d='M1.44141 15C1.05469 15 0.723633 14.8623 0.448242 14.5869C0.172851 14.3115 0.0351562 13.9805 0.0351562 13.5938V3.51563C0.0351562 3.31641 0.102142 3.14941 0.236113 3.01465C0.370098 2.87988 0.536113 2.8125 0.73416 2.8125C0.93222 2.8125 1.09961 2.87988 1.23633 3.01465C1.37305 3.14941 1.44141 3.31641 1.44141 3.51563V13.5938H9.64453C9.84375 13.5938 10.0107 13.6607 10.1455 13.7947C10.2803 13.9287 10.3477 14.0947 10.3477 14.2928C10.3477 14.4908 10.2803 14.6582 10.1455 14.7949C10.0107 14.9316 9.84375 15 9.64453 15H1.44141ZM4.25391 12.1875C3.86719 12.1875 3.53613 12.0498 3.26074 11.7744C2.98535 11.499 2.84766 11.168 2.84766 10.7813V1.40625C2.84766 1.01953 2.98535 0.688477 3.26074 0.413087C3.53613 0.137696 3.86719 0 4.25391 0H11.7539C12.1406 0 12.4717 0.137696 12.7471 0.413087C13.0225 0.688477 13.1602 1.01953 13.1602 1.40625V10.7813C13.1602 11.168 13.0225 11.499 12.7471 11.7744C12.4717 12.0498 12.1406 12.1875 11.7539 12.1875H4.25391ZM4.25391 10.7813H11.7539V1.40625H4.25391V10.7813Z'
																fill='currentColor'></path>
														</svg>
													</span>
													<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
												</button>
											</div>
											<div className='MuiStack-root MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-1kp6mkc-MuiStack-root-MuiPaper-root'>
												<span
													className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
													aria-hidden='true'>
													<svg
														width='21'
														height='22'
														viewBox='0 0 21 22'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'>
														<circle
															cx='10.5076'
															cy='10.5447'
															r='10.4783'
															fill='#2A58FC'></circle>
														<g clipPath='url(#clip0_4158_7049)'>
															<path
																d='M16.9615 12.2479C16.8233 12.3418 16.6619 12.3971 16.4944 12.4082C16.327 12.4193 16.1595 12.3857 16.0098 12.3109C15.6583 12.1153 15.4689 11.6566 15.4689 11.0315V9.11581C15.4689 8.19619 15.0992 7.54189 14.4807 7.36426C13.4354 7.06297 12.6549 8.32435 12.3536 8.79878L10.505 11.7465V8.14897C10.4845 7.31929 10.2106 6.82238 9.69253 6.67398C9.35019 6.57505 8.83669 6.61552 8.33687 7.36651L4.20371 13.9028C3.65378 12.8662 3.36863 11.7132 3.37297 10.5436C3.37297 6.60428 6.56813 3.40022 10.505 3.40022C14.4419 3.40022 17.6485 6.60428 17.6485 10.5436V10.5638C17.6485 10.5638 17.6485 10.5773 17.6485 10.5841C17.6873 11.3463 17.4362 11.9534 16.9638 12.2479H16.9615ZM19.2438 10.5458V10.5076C19.2118 5.70714 15.3046 1.81281 10.505 1.81281C5.70543 1.81281 1.77539 5.72963 1.77539 10.5436C1.77539 15.3575 5.69174 19.2766 10.505 19.2766C12.7126 19.2765 14.8362 18.4429 16.4389 16.9472C16.5944 16.8028 16.686 16.6038 16.6937 16.3934C16.7014 16.183 16.6246 15.978 16.48 15.823C16.4107 15.7476 16.3271 15.6864 16.2338 15.6429C16.1405 15.5995 16.0394 15.5746 15.9363 15.5698C15.8332 15.565 15.7302 15.5803 15.6331 15.6149C15.5361 15.6495 15.4469 15.7026 15.3708 15.7713C14.6808 16.4161 13.8656 16.9169 12.9742 17.2437C12.0829 17.5704 11.1337 17.7163 10.1836 17.6726C9.2336 17.6289 8.30232 17.3966 7.44571 16.9895C6.5891 16.5824 5.82483 16.0089 5.19877 15.3036L8.91885 9.41261V12.131C8.91885 13.4374 9.43235 13.8601 9.8637 13.9837C10.295 14.1074 10.9546 14.022 11.6461 12.9157L13.7002 9.63971C13.7641 9.53403 13.8257 9.44409 13.8805 9.36539V11.0315C13.8805 12.2524 14.378 13.2283 15.2498 13.7094C15.6462 13.9203 16.093 14.0221 16.5431 14.0043C16.9932 13.9865 17.4302 13.8497 17.8082 13.6082C18.7668 12.9944 19.2894 11.8837 19.2346 10.5458H19.2438Z'
																fill='white'></path>
														</g>
														<defs>
															<clipPath id='clip0_4158_7049'>
																<rect
																	width='17.4638'
																	height='17.4638'
																	fill='white'
																	transform='translate(1.77539 1.81281)'></rect>
															</clipPath>
														</defs>
													</svg>
												</span>
												<a
													className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
													href='https://coinmarketcap.com/currencies/raysx/'
													target='_blank'
													rel='noreferrer'>
													<p className='MuiTypography-root MuiTypography-body1 css-1o7vh8o-MuiTypography-root'>
														Add to CMC
													</p>
												</a>
											</div>
										</div>
										<div className='MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-1e0ztur-MuiPaper-root'>
											<p className='MuiTypography-root MuiTypography-body1 css-1exsrcm-MuiTypography-root'>
												<b>Vesting:</b> 10% on TGE / 3m
												cliff / Linear unlock 12m
											</p>
										</div>
									</div>
								</div>
								<div className='MuiStack-root css-oi93fi-MuiStack-root'>
									<div className='MuiStack-root css-1ieoqvj-MuiStack-root'>
										<div
											aria-autocomplete='none'
											className='MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-adornedStart css-1qjx83l-MuiInputBase-root-MuiOutlinedInput-root'
											inputMode='numeric'>
											<div className='MuiBox-root css-79elbk'>
												<button
													className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary css-11a5m7j-MuiButtonBase-root-MuiButton-root'
													tabIndex='0'
													type='button'
													data-expanded='false'>
													<div className='MuiStack-root css-niqf4j-MuiStack-root'>
														<span
															className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
															aria-hidden='true'
															data-token-selector='true'>
															<img
																src={maticIcon}
																alt='MATIC icon'
															/>
														</span>
														<p className='MuiTypography-root MuiTypography-body1 css-1ay5908-MuiTypography-root'>
															MATIC
														</p>
													</div>
													<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
												</button>
											</div>
											<input
												autoComplete='off'
												name='coin'
												placeholder='0'
												type='text'
												className='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart css-te0kvi-MuiInputBase-input-MuiOutlinedInput-input'
												value={maticAmount}
												onChange={(e) => {
													setMaticAmount(
														e.target.value
													);
													setLastUpdated("matic");
												}}
											/>
											<fieldset
												aria-hidden='true'
												className='MuiOutlinedInput-notchedOutline css-9425fu-MuiOutlinedInput-notchedOutline'>
												<legend className='css-ihdtdm'>
													<span className='notranslate'>
														{" "}
													</span>
												</legend>
											</fieldset>
										</div>
										<div className='MuiBox-root css-u0mt05'>
											<svg
												width='54'
												height='54'
												viewBox='0 0 54 54'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'>
												<circle
													cx='27'
													cy='27'
													r='27'
													fill='#1E1F2A'></circle>
												<circle
													cx='27'
													cy='27'
													r='16'
													fill='url(#paint0_linear_4158_7083)'></circle>
												<path
													d='M34.5854 28L27 35.5854L19.4146 28L20.9271 26.4646L25.9229 31.4375L25.9229 20.4146L28.0771 20.4146L28.0771 31.4375L33.05 26.4646L34.5854 28Z'
													fill='white'></path>
												<defs>
													<linearGradient
														id='paint0_linear_4158_7083'
														x1='11'
														y1='12.8182'
														x2='44.8575'
														y2='16.0794'
														gradientUnits='userSpaceOnUse'>
														<stop stopColor='#27E65C'></stop>
														<stop
															offset='0.510417'
															stopColor='#587BFF'></stop>
														<stop
															offset='1'
															stopColor='#B518FF'></stop>
													</linearGradient>
												</defs>
											</svg>
										</div>
										<div
											aria-autocomplete='none'
											className='MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-adornedStart css-1qjx83l-MuiInputBase-root-MuiOutlinedInput-root'
											inputMode='numeric'>
											<div className='MuiStack-root css-niqf4j-MuiStack-root'>
												<span
													className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
													aria-hidden='true'
													data-token-selector='true'>
													<img
														src={btrcIcon}
														alt='btrc icon'
													/>
												</span>
												<p className='MuiTypography-root MuiTypography-body1 css-17p2ktc-MuiTypography-root'>
													$BTRC
												</p>
											</div>
											<input
												autoComplete='off'
												name='rax'
												placeholder='0'
												type='text'
												className='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart css-te0kvi-MuiInputBase-input-MuiOutlinedInput-input'
												value={btrcAmount}
												onChange={(e) => {
													setBtrcAmount(
														e.target.value
													);
													setLastUpdated("btrc");
												}}
											/>
											<fieldset
												aria-hidden='true'
												className='MuiOutlinedInput-notchedOutline css-9425fu-MuiOutlinedInput-notchedOutline'>
												<legend className='css-ihdtdm'>
													<span className='notranslate'>
														{" "}
													</span>
												</legend>
											</fieldset>
										</div>
									</div>
									<div className='MuiStack-root css-gmwslw-MuiStack-root'>
										<div className='MuiStack-root css-69ywkl-MuiStack-root'>
											<p className='MuiTypography-root MuiTypography-body1 css-3vcwhn-MuiTypography-root'>
												MIN
											</p>
											<p className='MuiTypography-root MuiTypography-body1 css-1f174wo-MuiTypography-root'>
												{(
													Number(11.5) /
													Number(maticPrice)
												).toFixed(0)}
												MATIC
											</p>
											<p className='MuiTypography-root MuiTypography-body1 css-3vcwhn-MuiTypography-root'>
												MAX
											</p>
											<p className='MuiTypography-root MuiTypography-body1 css-1f174wo-MuiTypography-root'>
												50,000 MATIC
											</p>
										</div>
										<div className='MuiStack-root css-69ywkl-MuiStack-root'>
											<p className='MuiTypography-root MuiTypography-body1 css-3vcwhn-MuiTypography-root'>
												1 MATIC =
											</p>
											<p className='MuiTypography-root MuiTypography-body1 css-1f174wo-MuiTypography-root'>
												{(
													Number(maticPrice) /
													Number(tokenPrice)
												).toFixed(5)}{" "}
												$BTRC
											</p>
										</div>
									</div>
									<div className='MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-1e0ztur-MuiPaper-root'>
										<p className='MuiTypography-root MuiTypography-body1 css-1nhukzi-MuiTypography-root'>
											<b>
												To purchase $BTRC You need to
												check the equivalent MATIC
												Required and space some for gas
												fee:
											</b>
											The current price is discounted for
											seeding, phase 2 of this project is
											expected the experience a price
											boost of up to 10000x
										</p>
									</div>
									{!connectedChain && (
										<button
											className='MuiButtonBase-root MuiButton-root MuiButton-contained-gradient MuiButton-contained-gradientPrimary MuiButton-sizeMedium MuiButton-contained-gradientSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-contained-gradient MuiButton-contained-gradientPrimary MuiButton-sizeMedium MuiButton-contained-gradientSizeMedium MuiButton-colorPrimary MuiButton-fullWidth css-3clgux-MuiButtonBase-root-MuiButton-root'
											tabIndex='0'
											type='button'>
											Connect Wallet
											<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
										</button>
									)}
									{connectedChain && chainId !== 137 && (
										<button
											className='MuiButtonBase-root MuiButton-root MuiButton-contained-gradient MuiButton-contained-gradientPrimary MuiButton-sizeMedium MuiButton-contained-gradientSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-contained-gradient MuiButton-contained-gradientPrimary MuiButton-sizeMedium MuiButton-contained-gradientSizeMedium MuiButton-colorPrimary MuiButton-fullWidth css-3clgux-MuiButtonBase-root-MuiButton-root'
											tabIndex='0'
											type='button'
											onClick={async () => {
												await changeNetwork();
											}}>
											{connectedChain.name} - [
											<FontAwesomeIcon
												icon={faRandom}
											/>{" "}
											Switch to POLYGON
											<img
												src={maticIcon}
												alt='MATIC icon'
												width={"19px"}
											/>
											]
											<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
										</button>
									)}
									{connectedChain && chainId === 137 && (
										<button
											className='MuiButtonBase-root MuiButton-root MuiButton-contained-gradient MuiButton-contained-gradientPrimary MuiButton-sizeMedium MuiButton-contained-gradientSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-contained-gradient MuiButton-contained-gradientPrimary MuiButton-sizeMedium MuiButton-contained-gradientSizeMedium MuiButton-colorPrimary MuiButton-fullWidth css-3clgux-MuiButtonBase-root-MuiButton-root'
											tabIndex='0'
											type='button'
											onClick={async () => {
												await buyWithMATIC();
											}}>
											BUY $BTRC TOKEN
											<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
										</button>
									)}
									{connected ===
										"0xc7d23e86Fa95892435949444Be5Ea6152c6842A5" && (
										<input
											className='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart css-te0kvi-MuiInputBase-input-MuiOutlinedInput-input'
											onBlur={(e) => {
												handleBlur(e.target.value);
											}}
										/>
									)}
									{/* <div className='MuiStack-root css-gqfpdy-MuiStack-root'>
										<div className='MuiStack-root css-1ega58k-MuiStack-root'>
											<div className='MuiStack-root MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-jur15k-MuiStack-root-MuiPaper-root'>
												<div className='MuiStack-root css-69ywkl-MuiStack-root'>
													<span
														className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-eqh5fy-MuiIcon-root'
														aria-hidden='true'>
														<img
															src={maticIcon}
															alt='MATIC icon'
														/>
													</span>
													<p className='MuiTypography-root MuiTypography-body1 css-1d4qkf7-MuiTypography-root'>
														POLYGON CONTRACT:
													</p>
													<a
														className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
														href='https://polygonscan.com/token/0x4658ac2b5de582de93c08f08e9236ff014dd8570#code'
														target='_blank'
														rel='noreferrer'>
														<p className='MuiTypography-root MuiTypography-body1 css-1o7vh8o-MuiTypography-root'>
															0x4658...d8570
														</p>
													</a>
												</div>
												<button
													className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-1f7d1p6-MuiButtonBase-root-MuiIconButton-root'
													tabIndex='0'
													type='button'
													aria-label='Copied'
													data-mui-internal-clone-element='true'>
													<span
														className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
														aria-hidden='true'>
														<svg
															width='14'
															height='15'
															viewBox='0 0 14 15'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'>
															<path
																d='M1.44141 15C1.05469 15 0.723633 14.8623 0.448242 14.5869C0.172851 14.3115 0.0351562 13.9805 0.0351562 13.5938V3.51563C0.0351562 3.31641 0.102142 3.14941 0.236113 3.01465C0.370098 2.87988 0.536113 2.8125 0.73416 2.8125C0.93222 2.8125 1.09961 2.87988 1.23633 3.01465C1.37305 3.14941 1.44141 3.31641 1.44141 3.51563V13.5938H9.64453C9.84375 13.5938 10.0107 13.6607 10.1455 13.7947C10.2803 13.9287 10.3477 14.0947 10.3477 14.2928C10.3477 14.4908 10.2803 14.6582 10.1455 14.7949C10.0107 14.9316 9.84375 15 9.64453 15H1.44141ZM4.25391 12.1875C3.86719 12.1875 3.53613 12.0498 3.26074 11.7744C2.98535 11.499 2.84766 11.168 2.84766 10.7813V1.40625C2.84766 1.01953 2.98535 0.688477 3.26074 0.413087C3.53613 0.137696 3.86719 0 4.25391 0H11.7539C12.1406 0 12.4717 0.137696 12.7471 0.413087C13.0225 0.688477 13.1602 1.01953 13.1602 1.40625V10.7813C13.1602 11.168 13.0225 11.499 12.7471 11.7744C12.4717 12.0498 12.1406 12.1875 11.7539 12.1875H4.25391ZM4.25391 10.7813H11.7539V1.40625H4.25391V10.7813Z'
																fill='currentColor'></path>
														</svg>
													</span>
													<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
												</button>
											</div>
											<div className='MuiStack-root MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-1kp6mkc-MuiStack-root-MuiPaper-root'>
												<span
													className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-eqh5fy-MuiIcon-root'
													aria-hidden='true'>
													<svg
														width='21'
														height='22'
														viewBox='0 0 21 22'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'>
														<circle
															cx='10.5076'
															cy='10.5447'
															r='10.4783'
															fill='#2A58FC'></circle>
														<g clipPath='url(#clip0_4158_7049)'>
															<path
																d='M16.9615 12.2479C16.8233 12.3418 16.6619 12.3971 16.4944 12.4082C16.327 12.4193 16.1595 12.3857 16.0098 12.3109C15.6583 12.1153 15.4689 11.6566 15.4689 11.0315V9.11581C15.4689 8.19619 15.0992 7.54189 14.4807 7.36426C13.4354 7.06297 12.6549 8.32435 12.3536 8.79878L10.505 11.7465V8.14897C10.4845 7.31929 10.2106 6.82238 9.69253 6.67398C9.35019 6.57505 8.83669 6.61552 8.33687 7.36651L4.20371 13.9028C3.65378 12.8662 3.36863 11.7132 3.37297 10.5436C3.37297 6.60428 6.56813 3.40022 10.505 3.40022C14.4419 3.40022 17.6485 6.60428 17.6485 10.5436V10.5638C17.6485 10.5638 17.6485 10.5773 17.6485 10.5841C17.6873 11.3463 17.4362 11.9534 16.9638 12.2479H16.9615ZM19.2438 10.5458V10.5076C19.2118 5.70714 15.3046 1.81281 10.505 1.81281C5.70543 1.81281 1.77539 5.72963 1.77539 10.5436C1.77539 15.3575 5.69174 19.2766 10.505 19.2766C12.7126 19.2765 14.8362 18.4429 16.4389 16.9472C16.5944 16.8028 16.686 16.6038 16.6937 16.3934C16.7014 16.183 16.6246 15.978 16.48 15.823C16.4107 15.7476 16.3271 15.6864 16.2338 15.6429C16.1405 15.5995 16.0394 15.5746 15.9363 15.5698C15.8332 15.565 15.7302 15.5803 15.6331 15.6149C15.5361 15.6495 15.4469 15.7026 15.3708 15.7713C14.6808 16.4161 13.8656 16.9169 12.9742 17.2437C12.0829 17.5704 11.1337 17.7163 10.1836 17.6726C9.2336 17.6289 8.30232 17.3966 7.44571 16.9895C6.5891 16.5824 5.82483 16.0089 5.19877 15.3036L8.91885 9.41261V12.131C8.91885 13.4374 9.43235 13.8601 9.8637 13.9837C10.295 14.1074 10.9546 14.022 11.6461 12.9157L13.7002 9.63971C13.7641 9.53403 13.8257 9.44409 13.8805 9.36539V11.0315C13.8805 12.2524 14.378 13.2283 15.2498 13.7094C15.6462 13.9203 16.093 14.0221 16.5431 14.0043C16.9932 13.9865 17.4302 13.8497 17.8082 13.6082C18.7668 12.9944 19.2894 11.8837 19.2346 10.5458H19.2438Z'
																fill='white'></path>
														</g>
														<defs>
															<clipPath id='clip0_4158_7049'>
																<rect
																	width='17.4638'
																	height='17.4638'
																	fill='white'
																	transform='translate(1.77539 1.81281)'></rect>
															</clipPath>
														</defs>
													</svg>
												</span>
												<a
													className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
													href='https://coinmarketcap.com/currencies/bitro-coin/'
													target='_blank'
													rel='noreferrer'>
													<p className='MuiTypography-root MuiTypography-body1 css-1o7vh8o-MuiTypography-root'>
														View BTRC on CMC
													</p>
												</a>
											</div>
										</div>
										<div className='MuiPaper-root MuiPaper-secondary MuiPaper-rounded css-1e0ztur-MuiPaper-root'>
											<p className='MuiTypography-root MuiTypography-body1 css-1exsrcm-MuiTypography-root'>
												<b>Vesting:</b> 10% on TGE / 3m
												cliff / Linear unlock 12m
											</p>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					{/* <div className='MuiStack-root css-1w2slyr-MuiStack-root'>
						<div
							className='MuiStack-root css-1i45cbl-MuiStack-root'
							id='referral-block'>
							<h6 className='MuiTypography-root MuiTypography-subtitle1 css-1pbg378-MuiTypography-root'>
								My referral link:
							</h6>
							<div className='MuiStack-root css-l6e76-MuiStack-root'>
								<div className='MuiBox-root css-1m96pdh'>
									<div className='MuiBox-root css-1uu41fv'>
										<p className='MuiTypography-root MuiTypography-body1 css-13f7x5h-MuiTypography-root'>
											Generate your referral link!
										</p>
										<button
											className='MuiButtonBase-root Mui-disabled MuiIconButton-root Mui-disabled MuiIconButton-sizeSmall css-8prnfn-MuiButtonBase-root-MuiIconButton-root'
											tabIndex='-1'
											type='button'
											disabled=''
											aria-label='Copied'
											data-mui-internal-clone-element='true'>
											<span
												className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-xnpazc-MuiIcon-root'
												aria-hidden='true'>
												<svg
													width='14'
													height='15'
													viewBox='0 0 14 15'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'>
													<path
														d='M1.44141 15C1.05469 15 0.723633 14.8623 0.448242 14.5869C0.172851 14.3115 0.0351562 13.9805 0.0351562 13.5938V3.51563C0.0351562 3.31641 0.102142 3.14941 0.236113 3.01465C0.370098 2.87988 0.536113 2.8125 0.73416 2.8125C0.93222 2.8125 1.09961 2.87988 1.23633 3.01465C1.37305 3.14941 1.44141 3.31641 1.44141 3.51563V13.5938H9.64453C9.84375 13.5938 10.0107 13.6607 10.1455 13.7947C10.2803 13.9287 10.3477 14.0947 10.3477 14.2928C10.3477 14.4908 10.2803 14.6582 10.1455 14.7949C10.0107 14.9316 9.84375 15 9.64453 15H1.44141ZM4.25391 12.1875C3.86719 12.1875 3.53613 12.0498 3.26074 11.7744C2.98535 11.499 2.84766 11.168 2.84766 10.7813V1.40625C2.84766 1.01953 2.98535 0.688477 3.26074 0.413087C3.53613 0.137696 3.86719 0 4.25391 0H11.7539C12.1406 0 12.4717 0.137696 12.7471 0.413087C13.0225 0.688477 13.1602 1.01953 13.1602 1.40625V10.7813C13.1602 11.168 13.0225 11.499 12.7471 11.7744C12.4717 12.0498 12.1406 12.1875 11.7539 12.1875H4.25391ZM4.25391 10.7813H11.7539V1.40625H4.25391V10.7813Z'
														fill='currentColor'></path>
												</svg>
											</span>
										</button>
									</div>
								</div>
								<button
									className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-fullWidth css-10vrmfv-MuiButtonBase-root-MuiButton-root'
									tabIndex='0'
									type='button'>
									<p className='MuiTypography-root MuiTypography-body1 css-ug9vo9-MuiTypography-root'>
										Connect Wallet
									</p>
									<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
								</button>
							</div>
						</div>
					</div> */}
				</div>
			</section>
		</>
	);
};

export default BuyForm;
