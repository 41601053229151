import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../img/logo.png";
const Footer = () => {
	return (
		<>
			<footer className='MuiStack-root css-1lm0x5v-MuiStack-root'>
				<div className='MuiStack-root css-ky2y9c-MuiStack-root'>
					<div className='MuiBox-root css-1fkz67v'>
						<img
							src={logo}
							alt='BitroCoin Logo'
						/>
					</div>
					<div className='MuiStack-root css-kwicyq-MuiStack-root'>
						<a
							className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
							href='https://x.com/_btrctoken'
							target='_blank'
							rel='noreferrer'>
							<button
								className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-w7hl22-MuiButtonBase-root-MuiIconButton-root'
								tabIndex='0'
								type='button'>
								<svg
									width='30'
									height='30'
									viewBox='0 0 30 30'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M20.99 5H24.298L17.071 13.26L25.573 24.5H18.916L13.702 17.683L7.736 24.5H4.426L12.156 15.665L4 5H10.826L15.539 11.231L20.99 5ZM19.829 22.52H21.662L9.83 6.876H7.863L19.829 22.52Z'
										fill='currentColor'></path>
								</svg>
								<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
							</button>
						</a>
						<a
							className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
							href='https://t.me/btrc_coin_official'
							target='_blank'
							rel='noreferrer'>
							<button
								className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-w7hl22-MuiButtonBase-root-MuiIconButton-root'
								tabIndex='0'
								type='button'>
								<svg
									width='30'
									height='30'
									viewBox='0 0 30 30'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M25.8871 4.56812C25.8871 4.56812 28.3154 3.62124 28.113 5.9208C28.0456 6.86769 27.4385 10.1817 26.9663 13.7664L25.3474 24.3849C25.3474 24.3849 25.2126 25.9405 23.9984 26.2111C22.7842 26.4816 20.963 25.2642 20.6257 24.9936C20.3559 24.7908 15.5667 21.7472 13.8804 20.2592C13.4082 19.8534 12.8686 19.0418 13.9478 18.0949L21.0304 11.3316C21.8399 10.5199 22.6493 8.62619 19.2766 10.9257L9.83319 17.351C9.83319 17.351 8.75394 18.0273 6.73038 17.4186L2.34589 16.0659C2.34589 16.0659 0.727007 15.0514 3.49259 14.0369C10.2379 10.8581 18.5347 7.61162 25.8871 4.56812Z'
										fill='currentColor'></path>
								</svg>
								<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
							</button>
						</a>
						<a
							className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
							href='mailto:dev@bitrocoin.io'
							target='_blank'
							rel='noreferrer'>
							<button
								className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-w7hl22-MuiButtonBase-root-MuiIconButton-root'
								tabIndex='0'
								type='button'>
								<FontAwesomeIcon icon={faEnvelope} />
								<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
							</button>
						</a>
					</div>
					<p className='MuiTypography-root MuiTypography-body1 css-1o9c0bb-MuiTypography-root'>
						Please note that by using BitroCoin.io & BitRodesk
						services you represent and warrant that you do not
						reside in and are not a citizen of a Restricted
						jurisdiction (in the meaning specified in our AML
						Policy, including but not limited to US citizens and
						residents in certain states), and no laws and
						regulations of your country in any way prohibit or
						restrict using our services via investments in BTRC
						Token.
					</p>
				</div>
				<div className='MuiStack-root css-kz9ab6-MuiStack-root'>
					<div className='MuiStack-root css-r1y62d-MuiStack-root'>
						<a
							className='MuiButtonBase-root MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary css-bm0zz9-MuiButtonBase-root-MuiButton-root'
							tabIndex='0'
							href='/'
							target='_blank'
							rel='noreferrer'>
							Blog
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</a>
						<a
							className='MuiButtonBase-root MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary css-bm0zz9-MuiButtonBase-root-MuiButton-root'
							tabIndex='0'
							href='/'
							target='_blank'
							rel='noreferrer'>
							Whitepaper
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</a>
					</div>
					<div className='MuiStack-root css-r1y62d-MuiStack-root'>
						<a
							className='MuiButtonBase-root MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary css-bm0zz9-MuiButtonBase-root-MuiButton-root'
							tabIndex='0'
							href='mailto:dev@bitrocoin.io'>
							Contact Us
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</a>
						<a
							className='MuiButtonBase-root MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text-link MuiButton-text-linkPrimary MuiButton-sizeMedium MuiButton-text-linkSizeMedium MuiButton-colorPrimary css-bm0zz9-MuiButtonBase-root-MuiButton-root'
							tabIndex='0'
							href='/'>
							Legal Notice
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</a>
					</div>
				</div>
				<div className='MuiStack-root css-1v7ggsw-MuiStack-root'>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
						href='https://x.com/_btrctoken'
						target='_blank'
						rel='noreferrer'>
						<button
							className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-w7hl22-MuiButtonBase-root-MuiIconButton-root'
							tabIndex='0'
							type='button'>
							<svg
								width='30'
								height='30'
								viewBox='0 0 30 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M20.99 5H24.298L17.071 13.26L25.573 24.5H18.916L13.702 17.683L7.736 24.5H4.426L12.156 15.665L4 5H10.826L15.539 11.231L20.99 5ZM19.829 22.52H21.662L9.83 6.876H7.863L19.829 22.52Z'
									fill='currentColor'></path>
							</svg>
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</button>
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
						href='https://t.me/btrc_coin_official'
						target='_blank'
						rel='noreferrer'>
						<button
							className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-w7hl22-MuiButtonBase-root-MuiIconButton-root'
							tabIndex='0'
							type='button'>
							<svg
								width='30'
								height='30'
								viewBox='0 0 30 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M25.8871 4.56812C25.8871 4.56812 28.3154 3.62124 28.113 5.9208C28.0456 6.86769 27.4385 10.1817 26.9663 13.7664L25.3474 24.3849C25.3474 24.3849 25.2126 25.9405 23.9984 26.2111C22.7842 26.4816 20.963 25.2642 20.6257 24.9936C20.3559 24.7908 15.5667 21.7472 13.8804 20.2592C13.4082 19.8534 12.8686 19.0418 13.9478 18.0949L21.0304 11.3316C21.8399 10.5199 22.6493 8.62619 19.2766 10.9257L9.83319 17.351C9.83319 17.351 8.75394 18.0273 6.73038 17.4186L2.34589 16.0659C2.34589 16.0659 0.727007 15.0514 3.49259 14.0369C10.2379 10.8581 18.5347 7.61162 25.8871 4.56812Z'
									fill='currentColor'></path>
							</svg>
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</button>
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
						href='mailto:dev@bitrocoin.io'
						target='_blank'
						rel='noreferrer'>
						<button
							className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-w7hl22-MuiButtonBase-root-MuiIconButton-root'
							tabIndex='0'
							type='button'>
							<FontAwesomeIcon icon={faEnvelope} />
							<span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
						</button>
					</a>
				</div>
				<div className='MuiStack-root css-opdudl-MuiStack-root'>
					<p className='MuiTypography-root MuiTypography-body1 css-1ar4yh3-MuiTypography-root'>
						Please note that by using Ray.sX services you represent
						and warrant that you do not reside in and are not a
						citizen of a Restricted jurisdiction (in the meaning
						specified in our AML Policy, including but not limited
						to US citizens and residents in certain states), and no
						laws and regulations of your country in any way prohibit
						or restrict using our services via investments in RAX
						Token.
					</p>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1w1xlub-MuiTypography-root-MuiLink-root'
						href='https://bitrocoin.io/'
						target='_blank'
						rel='noreferrer'>
						<span className='MuiTypography-root MuiTypography-body1 css-1exsrcm-MuiTypography-root'>
							Powered by
						</span>
						<span className='MuiTypography-root MuiTypography-body1 css-198woa6-MuiTypography-root'>
							BitRoCoin for BitRodesk
						</span>
					</a>
					<p className='MuiTypography-root MuiTypography-body1 css-f920tp-MuiTypography-root'>
						© 2024 BitRo Project
					</p>
				</div>
			</footer>
		</>
	);
};
export default Footer;
