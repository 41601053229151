import binance from "../img/partners/binance.png";
import mantaNetwork from "../img/partners/manta-network.png";
import magicSquar from "../img/partners/magic-squar.png";
import eywa from "../img/partners/eywa.png";
import zetaChain from "../img/partners/zeta-chain.png";
import cryptorank from "../img/partners/cryptorank.png";
import dmail from "../img/partners/dmail.png";
import crossCurve from "../img/partners/cross-curve.png";
import tradingView from "../img/partners/trading-view.png";
import bitgert from "../img/partners/bitgert.png";
import drobsTab from "../img/partners/drobs-tab.png";
import dappadLaunchpad from "../img/partners/dappad-launchpad.png";

const Partners = () => {
	return (
		<>
			<section className='MuiStack-root css-12n70vr-MuiStack-root'>
				<h1 className='MuiTypography-root MuiTypography-h1 MuiTypography-alignCenter css-lxmiih-MuiTypography-root'>
					BitroCoin Strategic <b>Partners</b>
				</h1>
				<div className='MuiStack-root css-dgn5sw-MuiStack-root'>
					<img
						src={binance}
						alt='binance logo'
						className='css-7t0v2r'
					/>
					<img
						src={mantaNetwork}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={magicSquar}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={eywa}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={zetaChain}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={cryptorank}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={dmail}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={crossCurve}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={tradingView}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={bitgert}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={drobsTab}
						alt='logo'
						className='css-7t0v2r'
					/>
					<img
						src={dappadLaunchpad}
						alt='logo'
						className='css-7t0v2r'
					/>
				</div>
			</section>
		</>
	);
};

export default Partners;
