import btrcIcon from "../img/coin.png";
import maticIcon from "../img/matic.png";
const Tokenomics = () => {
	return (
		<>
			<section className='MuiStack-root css-12n70vr-MuiStack-root'>
				<h1 className='MuiTypography-root MuiTypography-h1 MuiTypography-alignCenter css-lxmiih-MuiTypography-root'>
					<b>$BTRC</b> Tokenomics
				</h1>
				<div className='MuiStack-root css-1x5n9c-MuiStack-root'>
					<div className='MuiBox-root css-1sotifh'>
						<div className='MuiStack-root css-1vwc2c2-MuiStack-root'>
							<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
								Token Name: BITRO COIN
							</p>
							<div className='MuiStack-root css-19jlz5w-MuiStack-root'>
								<div className='MuiBox-root css-18sp06o'>
									<img
										src={btrcIcon}
										alt='BTRC ico'
										width={"38px"}
									/>
								</div>
								<h6 className='MuiTypography-root MuiTypography-h6 css-19ybmo7-MuiTypography-root'>
									$BTRC
								</h6>
							</div>
						</div>
					</div>
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Tokens for Sale %:
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							18.00%
						</h6>
					</div>
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Network:
						</p>
						<div className='MuiStack-root css-amkvz9-MuiStack-root'>
							<div className='MuiStack-root css-19jlz5w-MuiStack-root'>
								<span
									className='material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-r2l8dc-MuiIcon-root'
									aria-hidden='true'>
									<img
										src={maticIcon}
										alt='MATIC icon'
									/>
								</span>
								<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
									POLYGON & BSC [ERC20 & BEP20]
								</h6>
							</div>
						</div>
					</div>
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Tokens for Sale (Total):
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							9,000,000
						</h6>
					</div>
					{/* <div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Total raised in all Rounds:
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							$1,987,500
						</h6>
					</div> */}
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Total Supply:
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							50,000,000
						</h6>
					</div>
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Initial Market Cap:
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							$80,000,000
						</h6>
					</div>
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Listing Token Price:
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							$2.65
						</h6>
					</div>
					<div className='MuiStack-root css-zi7fh8-MuiStack-root'>
						<p className='MuiTypography-root MuiTypography-body1 css-1heoqm2-MuiTypography-root'>
							Seed Round Valuation:
						</p>
						<h6 className='MuiTypography-root MuiTypography-h6 css-12977lx-MuiTypography-root'>
							$23,850,000
						</h6>
					</div>
					<div className='MuiStack-root css-eislj4-MuiStack-root'></div>
				</div>
			</section>
		</>
	);
};

export default Tokenomics;
