import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import banner from "../img/banner.gif";
import logo from "../img/logo.png";
const Sidebar = () => {
	return (
		<>
			<div
				className='MuiBox-root css-ual62l'
				data-opened='true'>
				<div className='MuiBox-root css-1jtsppg'>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
						href='/'>
						<img
							src={logo}
							alt='BitroCoin Logo'
						/>
					</a>
				</div>
				{/* <div className='MuiBox-root css-rcosed'>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						data-soon='true'
						href='/'>
						<FontAwesomeIcon icon={faVault} />
						BitroVault
						<div className='MuiBox-root css-13lqys5'>soon</div>
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						data-soon='true'
						href='/'>
						<FontAwesomeIcon icon={faShuffle} />
						BitroSwap
						<div className='MuiBox-root css-13lqys5'>soon</div>
					</a>
				</div>
				<div className='MuiBox-root css-ge4xg0'>
					<div className='MuiBox-root css-mfy0sx'>BTRC TOKEN HUB</div>
					<svg
						width='16'
						height='17'
						viewBox='0 0 16 17'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M14.6641 10.2873L14.6641 15.6206L9.33073 15.6206L9.33073 10.2873L14.6641 10.2873ZM14.6641 2.28727L14.6641 7.62061L9.33073 7.62061L9.33073 2.28727L14.6641 2.28727ZM6.66406 10.2873L6.66406 15.6206L1.33073 15.6206L1.33073 10.2873L6.66406 10.2873ZM6.66406 2.28727L6.66406 7.62061L1.33073 7.6206L1.33073 2.28727L6.66406 2.28727Z'
							fill='url(#paint0_linear_5150_1938)'></path>
						<defs>
							<linearGradient
								id='paint0_linear_5150_1938'
								x1='11.4333'
								y1='15.6206'
								x2='-0.644913'
								y2='9.02121'
								gradientUnits='userSpaceOnUse'>
								<stop stopColor='#587BFF'></stop>
								<stop
									offset='1'
									stopColor='#8C18FF'></stop>
							</linearGradient>
						</defs>
					</svg>
				</div>
				<div className='MuiBox-root css-rcosed'>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						href='/rax-crowdsale-s1'
						data-test-id='sideBarBuyRax'>
						<svg
							width='24'
							height='25'
							viewBox='0 0 24 25'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<circle
								cx='12'
								cy='12.9336'
								r='10'
								fill='#AEB0B7'></circle>
							<path
								d='M17.3332 18.7512H14.4132L11.8493 14.5849L9.32098 18.7512H6.50781L10.5495 12.8222L6.79269 7H9.67708L12.0451 10.9527L14.4488 7H17.1195L13.3449 12.7332L17.3332 18.7512Z'
								fill='#090A0E'></path>
						</svg>
						Buy RAX
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						href='/rax-airdrop-s1'
						data-test-id='sideBarAirdrop'>
						<svg
							width='24'
							height='25'
							viewBox='0 0 24 25'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M4 14.9541C4 13.2041 4.41667 11.6458 5.25 10.2791C6.08333 8.91244 7 7.76243 8 6.8291C9 5.89577 9.91667 5.18327 10.75 4.6916L12 3.9541V7.2541C12 7.87077 12.2083 8.35827 12.625 8.7166C13.0417 9.07493 13.5083 9.2541 14.025 9.2541C14.3083 9.2541 14.5792 9.19577 14.8375 9.0791C15.0958 8.96243 15.3333 8.77077 15.55 8.5041L16 7.9541C17.2 8.6541 18.1667 9.62494 18.9 10.8666C19.6333 12.1083 20 13.4708 20 14.9541C20 16.4208 19.6417 17.7583 18.925 18.9666C18.2083 20.1749 17.2667 21.1291 16.1 21.8291C16.3833 21.4291 16.6042 20.9916 16.7625 20.5166C16.9208 20.0416 17 19.5374 17 19.0041C17 18.3374 16.875 17.7083 16.625 17.1166C16.375 16.5249 16.0167 15.9958 15.55 15.5291L12 12.0541L8.475 15.5291C7.99167 16.0124 7.625 16.5458 7.375 17.1291C7.125 17.7124 7 18.3374 7 19.0041C7 19.5374 7.07917 20.0416 7.2375 20.5166C7.39583 20.9916 7.61667 21.4291 7.9 21.8291C6.73333 21.1291 5.79167 20.1749 5.075 18.9666C4.35833 17.7583 4 16.4208 4 14.9541ZM12 14.8541L14.125 16.9291C14.4083 17.2124 14.625 17.5291 14.775 17.8791C14.925 18.2291 15 18.6041 15 19.0041C15 19.8208 14.7083 20.5166 14.125 21.0916C13.5417 21.6666 12.8333 21.9541 12 21.9541C11.1667 21.9541 10.4583 21.6666 9.875 21.0916C9.29167 20.5166 9 19.8208 9 19.0041C9 18.6208 9.075 18.2499 9.225 17.8916C9.375 17.5333 9.59167 17.2124 9.875 16.9291L12 14.8541Z'
								fill='#AEB0B7'></path>
						</svg>
						Airdrop
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						href='/leaderboard'
						data-test-id='sideBarLeaderbord'>
						<svg
							width='24'
							height='25'
							viewBox='0 0 24 25'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M7.475 21.9541C5.95833 21.9541 4.66667 21.4208 3.6 20.3541C2.53333 19.2874 2 17.9958 2 16.4791C2 15.0458 2.47917 13.8083 3.4375 12.7666C4.39583 11.7249 5.59167 11.1458 7.025 11.0291L3 2.9541H10L12 6.9541L14 2.9541H21L17 10.9791C18.4167 11.1124 19.6042 11.6999 20.5625 12.7416C21.5208 13.7833 22 15.0208 22 16.4541C22 17.9874 21.4667 19.2874 20.4 20.3541C19.3333 21.4208 18.0333 21.9541 16.5 21.9541C16.35 21.9541 16.1958 21.9499 16.0375 21.9416C15.8792 21.9333 15.725 21.9124 15.575 21.8791C16.4917 21.2791 17.2083 20.4999 17.725 19.5416C18.2417 18.5833 18.5 17.5541 18.5 16.4541C18.5 14.6374 17.8708 13.0999 16.6125 11.8416C15.3542 10.5833 13.8167 9.9541 12 9.9541C10.1833 9.9541 8.64583 10.5833 7.3875 11.8416C6.12917 13.0999 5.5 14.6374 5.5 16.4541C5.5 17.5874 5.73333 18.6541 6.2 19.6541C6.66667 20.6541 7.4 21.3958 8.4 21.8791C8.25 21.9124 8.09583 21.9333 7.9375 21.9416C7.77917 21.9499 7.625 21.9541 7.475 21.9541ZM12 20.9541C10.75 20.9541 9.6875 20.5166 8.8125 19.6416C7.9375 18.7666 7.5 17.7041 7.5 16.4541C7.5 15.2041 7.9375 14.1416 8.8125 13.2666C9.6875 12.3916 10.75 11.9541 12 11.9541C13.25 11.9541 14.3125 12.3916 15.1875 13.2666C16.0625 14.1416 16.5 15.2041 16.5 16.4541C16.5 17.7041 16.0625 18.7666 15.1875 19.6416C14.3125 20.5166 13.25 20.9541 12 20.9541ZM10.15 19.2041L12 17.8041L13.85 19.2041L13.15 16.9291L15 15.6041H12.725L12 13.2041L11.275 15.6041H9L10.85 16.9291L10.15 19.2041Z'
								fill='#AEB0B7'></path>
						</svg>
						Leaderboard
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						href='/vesting'
						data-test-id='sideBarVestingPortal'>
						<svg
							width='21'
							height='20'
							viewBox='0 0 21 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<g clipPath='url(#clip0_4195_7458)'>
								<path
									d='M15.5002 7.08342H14.6668V5.50008C14.6668 3.31508 12.8002 1.54175 10.5002 1.54175C8.20016 1.54175 6.3335 3.31508 6.3335 5.50008V7.08342H5.50016C4.5835 7.08342 3.8335 7.79591 3.8335 8.66675V16.5834C3.8335 17.4542 4.5835 18.1667 5.50016 18.1667H15.5002C16.4168 18.1667 17.1668 17.4542 17.1668 16.5834V8.66675C17.1668 7.79591 16.4168 7.08342 15.5002 7.08342ZM10.5002 14.2084C9.5835 14.2084 8.8335 13.4959 8.8335 12.6251C8.8335 11.7542 9.5835 11.0417 10.5002 11.0417C11.4168 11.0417 12.1668 11.7542 12.1668 12.6251C12.1668 13.4959 11.4168 14.2084 10.5002 14.2084ZM8.00016 7.08342V5.50008C8.00016 4.18591 9.11683 3.12508 10.5002 3.12508C11.8835 3.12508 13.0002 4.18591 13.0002 5.50008V7.08342H8.00016Z'
									fill='currentColor'></path>
							</g>
							<defs>
								<clipPath id='clip0_4195_7458'>
									<rect
										width='20'
										height='19'
										fill='white'
										transform='translate(0.5 0.75)'></rect>
								</clipPath>
							</defs>
						</svg>
						Vesting Portal
					</a>
				</div> */}
				<div
					className='MuiBox-root css-rcosed'
					data-info='true'>
					<img
						src={banner}
						alt='banner'
					/>
					{/* <a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1wyb9cq-MuiTypography-root-MuiLink-root'
						href='/'>
						<img
							src='/assets/images/join-the-quest.png'
							className='css-q5phwg'
							alt=''
						/>
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						href='https://raysx.gitbook.io/'
						target='_blank'
						rel='noreferrer'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M9.6 15.6L11 14.175L8.825 12L11 9.825L9.6 8.4L6 12L9.6 15.6ZM14.4 15.6L18 12L14.4 8.4L13 9.825L15.175 12L13 14.175L14.4 15.6ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5Z'
								fill='#AEB0B7'></path>
						</svg>
						Dev Docs
					</a>
					<a
						className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-q38y8q-MuiTypography-root-MuiLink-root'
						href='https://medium.com/@raysx'
						target='_blank'
						rel='noreferrer'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<g clipPath='url(#clip0_5150_2231)'>
								<path
									d='M19 6C18.1667 6 17.4583 5.70833 16.875 5.125C16.2917 4.54167 16 3.83333 16 3C16 2.16667 16.2917 1.45833 16.875 0.875C17.4583 0.291667 18.1667 0 19 0C19.8333 0 20.5417 0.291667 21.125 0.875C21.7083 1.45833 22 2.16667 22 3C22 3.83333 21.7083 4.54167 21.125 5.125C20.5417 5.70833 19.8333 6 19 6ZM2 22V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H14.1C14.0333 2.33333 14 2.66667 14 3C14 3.33333 14.0333 3.66667 14.1 4C14.1833 4.38333 14.3 4.74167 14.45 5.075C14.6 5.40833 14.7833 5.71667 15 6H6V8H19C19.5333 8 20.0583 7.91667 20.575 7.75C21.0917 7.58333 21.5667 7.33333 22 7V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6L2 22ZM6 11H18V9H6V11ZM6 14H14V12H6V14Z'
									fill='#AEB0B7'></path>
							</g>
							<defs>
								<clipPath id='clip0_5150_2231'>
									<rect
										width='24'
										height='24'
										fill='white'></rect>
								</clipPath>
							</defs>
						</svg>
						Blog
					</a> */}
					<div className='MuiBox-root css-1rj2yu3'>SOCIAL LINKS</div>
					<div className='MuiBox-root css-18bnemf'>
						<a
							className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1rvwuxc-MuiTypography-root-MuiLink-root'
							href='https://x.com/_btrctoken'
							target='_blank'
							rel='noreferrer'>
							<FontAwesomeIcon icon={faTwitter} />
						</a>
						<a
							className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1rvwuxc-MuiTypography-root-MuiLink-root'
							href='https://t.me/btrc_coin_official'
							target='_blank'
							rel='noreferrer'>
							<FontAwesomeIcon icon={faTelegram} />
						</a>
						<a
							className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-1rvwuxc-MuiTypography-root-MuiLink-root'
							href='mailto:dev@bitrocoin.io'
							target='_blank'
							rel='noreferrer'>
							<FontAwesomeIcon icon={faEnvelope} />
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
