import about from "../img/about.png";

const About = () => {
	return (
		<>
			<div className='MuiStack-root css-r2epb7-MuiStack-root'>
				<img
					src={about}
					className='css-lu325g'
					alt='About BitroCoin'
				/>
				<div className='MuiStack-root css-8uoze9-MuiStack-root'>
					<p className='MuiTypography-root MuiTypography-body1 css-1o2lu4c-MuiTypography-root'>
						<span
							fontSize='[object Object]'
							fontWeight='700'
							className='css-j9ol4l'>
							Seamless Innovation <br />
						</span>{" "}
						BitroDesk & $BTRC
					</p>
					<p className='MuiTypography-root MuiTypography-body1 css-1yz21j2-MuiTypography-root'>
						BitroDesk is a premier cryptocurrency trading platform
						that seamlessly blends decentralized finance with
						traditional market efficiencies, backed by robust
						security. Our native token, BitroCoin (BTRC),
						facilitates reduced trading fees, empowers users through
						governance, and offers exclusive platform features. With
						a limited supply of 50 million, BTRC exemplifies our
						commitment to a community-driven ecosystem advancing the
						global blockchain landscape.
					</p>
				</div>
			</div>
		</>
	);
};

export default About;
