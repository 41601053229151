import About from "./components/About";
import BuyForm from "./components/BuyForm";
// import Faqs from "./components/Faqs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Partners from "./components/Partners";
import Sidebar from "./components/Sidebar";
import Tokenomics from "./components/Tokenomics";

const App = () => {
	return (
		<>
			<div className='MuiStack-root css-1m55lwp-MuiStack-root'>
				<Header />
				<div className='MuiStack-root css-1vmfwhl-MuiStack-root'>
					<div className='Toastify'></div>
					<Sidebar />
					<div className='MuiStack-root css-1fwj7hr-MuiStack-root'>
						<BuyForm />
						<About />
						<Tokenomics />
						<Partners />
						{/* <Faqs /> */}
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default App;
